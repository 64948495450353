const english = {
    ISO: 'EN',
    Distributer: 'Distributor for BIH:',
    DistributerRS: 'Distributor for SRB:',
    DistributerHR: 'Distributor for HR:',
    Pretplacen: 'You have successfully subscribed',
    Copyright: '©2019-2022 Bio Plar All rights reserved',
    UsloviKoristenja: 'Terms of service',
    Pocetna: 'Home',
    Onama: 'About us',
    Katalog: 'Catalog',
    KontaktirajteNas: 'Contact us',
    ZastoPrirodno: 'Why natural',
    Pretrazuj: 'Search',
    PopularnoOveSedmice: 'POPULAR THIS WEEK',
    DodajUkorpu: 'Add to cart',
    prirodno100: '100% Natural',
    KontentJedan: '100% natural preparations confirmed by ISO and GMP certificates, which guarantee the quality and uniformity of the product \n\n Top quality ingredients that are difficult to find individually',
    Naglasci: 'HIGHLITES',
    BeskompromisnaKvaliteta: 'Uncompromising quality',
    KljucVasegZdravlja: 'THE KEY TO YOUR HEALTH IS IN NATURE',
    ZbogTogaSviNasiProizvodi: 'That is why all our products meet 3 criteria:',
    KontentDva: 'the ingredients used are of the highest quality and exclusively of natural origin, which is confirmed by international ISO and GMP standards',
    KontentTri: 'ensured through the offer of products developed in cooperation with international companies with 40 years of experience in the production of natural preparations',
    KontentCetiri: 'with 100% natural origin, our ingredients with regular use have a proven effect on alleviating ailments',
    NakonStoPopunite: 'After filling out the form, we will contact you during business hours, Mon-Sat 9am - 9pm.',
    ImeIprezime: 'Name and surname',
    BrojTelefona: 'Phone number',
    NazoviMe: 'CALL ME',
    DrustveneMreze: 'SOCIAL NETWORKS',
    PrimanjeNovosti: 'SIGN UP TO RECEIVE NEWS',
    Prihvatamo: 'WE ACCEPT',
    NasaPrica: 'Our story',
    KontentPet: 'We founded Bio Plar in 2019 with the aim of offering high-quality natural preparations that we wanted to buy, but we were not satisfied with the range and quality of products available on the market. The formula for the success of our products consists of two key elements: top-quality ingredients with proven effectiveness that are difficult to find on the free market, and cooperation with specialized pharmaceutical companies that produce natural preparations.\n\nIn this way, we offer you top-quality natural preparations that, in addition to regular using them allows you to improve the quality of life.\n\n\n\nFind the products that suit your needs and convince yourself of their effectiveness.',
    IdiNaWebShop: 'GO TO THE WEB SHOP',
    PrikazujeSe: 'Showing',
    Proizvoda: 'products',
    SortirajPo: 'SORT BY',
    Izaberite: 'CHOOSE',
    NazivAZ: 'Name A-Z',
    NazivZA: 'Name Z-A',
    CijenaNajvecaNajmanja: 'The highest price - the lowest',
    CijenaNajmanjaNajveca: 'The lowest price - the highest',
    BezSortiranja: 'No sorting',
    Osvjezi: 'Refresh',
    Cijena: 'PRICE',
    Naziv: 'NAME',
    Oboljenja: 'Illness',
    Hemoroidi: 'Hemorrhoids',
    KrvniPritisak: 'Blood pressure',
    Dijabetes: 'Diabetes',
    Mrsavljenje: 'Losing weight',
    Prostata: 'Prostate',
    Celulit: 'Cellulite',
    Potencija: 'Potency',
    Nedefinisano: 'Undefined',
    KontaktForma: 'CONTACT FORM',
    Ime: 'Name',
    Telefon: 'Phone',
    Poruka: 'Message',
    Adresa: 'Address',
    RadnoVrijeme: 'Working hours',
    ZastoPrirodno: 'Why natural',
    KontentSest: 'Ideally, you would produce natural preparations yourself from quality ingredients that you find in nature.\n\nDue to the time and money that needs to be invested in the preparation process, for most people the production of preparations is an impossible mission that has become our task.\n\nHigh-quality Bio Plar products help you alleviate everyday problems, such as hemorrhoids and obesity, which arise due to the pace and way of modern life.',
    NekeOdPrednostiNasihProizvodaSu: 'Some of the advantages of our products are:',
    Kolicina: 'Quantity',
    BesplatnaDostava: 'Free shipping',
    Obrisi: 'Delete',
    SazetakNarudzbe: 'Order summary',
    KuponKod: 'Coupon code',
    Primijeni: 'APPLY',
    Naruci: 'ORDER',
    UkupnoBezPDV: 'Total without VAT',
    PDV: 'VAT',
    Dostava: 'Shipping',
    Besplatno: 'Free',
    Ukupno: 'TOTAL',
    PopustNa: 'Discount on',
    UneseniKodJeNevazeci: 'The code entered is invalid...',
    UneseniKodJeIstekao: 'The code entered has expired...',
    KuponKod2: 'COUPON CODE:',
    NastaviNaOdabirDostave: 'CONTINUE TO SELECT SHIPPING',
    BrojKuce: 'House number',
    Grad: 'City',
    PostanskiBroj: 'ZIP',
    PrekiniNarudzbu: 'CANCEL ORDER',
    NastaviNaPlacanje: 'PROCEED TO PAYMENT',
    NacinPlacanja: 'Method of payment',
    PunoIme: 'Full name and surname of the cardholder',
    DatumIsticanja: 'Expiry date',
    ZIP: 'ZIP',
    BrojKartice: 'Card number',
    CVV: 'CVV',
    Ulica: 'Street',
    Email: 'Email',
    Napomena: 'Remark',
    Plati: 'PAY',
    PlatiPostaru: 'Pay the postman when you pick up the package',
    PreuzimanjePDF: 'Your estimate will be downloaded in the next few seconds...',
    UspjesnaNarudzba: 'You have successfully placed your order',
    NastaviKupovinu: 'CONTINUE SHOPPING',
    NazivKompanije: 'Company name',
    AdresaKompanije: 'Company address',
    PDVbroj: 'VAT number',
    IDbroj: 'ID number',
    SvaPoljaMorajuBitiPopunjena: 'All fields must be filled in!',
    PretplatiSe: 'SUBSCRIBE',
    Isporuka: 'Delivery',
    Placanje: 'Payment',
    DetaljiIsporuke: 'Delivery details',
    KorisnickoIme: 'Username',
    Lozinka: 'Password',
    Prijava: 'LOGIN',
    AkoNemateRacun: 'If you does not have an account',
    RegistrirajKlikom: 'register by clicking here',
    Registracija: 'REGISTRATION',
    PrijaviteSe: 'LOGIN',
    RegistrujSe: 'REGISTER HERE',
    NemaNaStanju: 'Currently out of stock',
    DOSTUPNO: 'AVAILABLE',
    DokazanaDjelotvornost: 'Proven effectiveness',
    PorukaUspjesnoPoslana: 'Message successfully sent',
    NemaProizvodaZaPrikaz: 'There are no products to display',
    NemateProizvodaUkosarici: 'You have no products in your cart',
    Naruči: 'Order',
    DetaljiDostave: 'Delivery details',
    NaciniPlacanja: 'Payment methods',
    VaseNarudzbe: 'Your orders',
    PostavkeProfila: 'My profile',
    VasiPodaciDostave: 'Your shipping information',
    Uredi: 'Edit',
    PodaciNaplate: 'Billing details',
    VasiNaciniPlacanja: 'Your payment methods',
    VasTrenutniNacinPlacanja: 'Your current payment method',
    Izmijenite: 'Edit:',
    DatumKreiranja: 'Creation date',
    Popust: 'Discount',
    PromijeniLozinku: 'Change password',
    SpremiLozinku: 'Save password',
    TrenutnaLozinka: 'Current password',
    NovaLozinka: 'New password',
    OdjaviSe: 'Sign out',
    Dobrodosli: 'Welcome',
    Sacuvaj: 'Save',
    DodajPodatkeZaDostavu: 'Add shipping information',
    TrenutnoNematePodatkeZaDostavu: 'You currently have no delivery information set, please fill out the form above',
    PostaviteZadaniNacinPlacanja: 'Set a default payment method',
    PodaciDostave: 'SHIPPING INFORMATION',
    PodaciDostave2: 'Shipping details',
    PodaciPlacanja: 'PAYMENT DATA',
    PodaciPlacanja2: 'Payment data',
    PogledajArtikle: 'View items',
    Proizvod: 'Products',
    PreporucenoZaVas: 'RECOMMENDED FOR YOU',
    JeDodan: 'has been added to your cart',
    IdiUkosaricu: 'GO TO CART',
    Oproizvodu: 'ABOUT PRODUCT',
    Upotreba: 'USAGE',
    Sastojci: 'INGREDIENTS',
    Upozorenja: 'WARNINGS',
    MisljenjaKupaca: 'OPINIONS OF OUR CUSTOMERS',
    Sastojak: 'Ingredient',
    Upozorenje: 'Warning:',
    NaKupovinu: 'on your purchase',
    Ostvarite10popusta: 'Get a 10% discount by signing up for e-news!',
    PotvrdjujemPolitikaPrivatnosti: 'I confirm that I am familiar with the privacy policy and allow the use of my e-mail address',
    UspjesnoSteSePrijavili: 'You have successfully subscribed!',
    CCphoneNumber: '+38532373309',
    Sluh: 'Hearing',
    Vid: 'Sight',
    PromjenaLozinke: 'Change password',
    ImatePitanja: 'You have a question about your order',
    PozoviteNas: 'Call us at the phone number shown above and our agents will provide you with all the information you need.',
    sigurna100kupovina: '100% safe shopping',
    Pobrinuli: 'We have ensured that your data is protected, so that you can safely shop on our website. Your data and privacy will not be shared.',
    PovratNovca: 'Refund',
    UkolikoIzBiloKojeg: 'If for any reason you are not satisfied with the product, you can return it within 15 days, and we will refund your money.',
    PlacanjeNaRate: 'Paying in installments',
    NudimoVamPlacanjeNaRate: 'We offer you several different payment methods, and you choose the one that suits you best. You can pay upon delivery, in up to 24 installments or online using your card. When it comes to paying in 24 installments, you can agree on the number of installments in a conversation with our friendly operators.',
    UspjesnoSteNarucili: 'You have successfully placed your order!',
    KolicinaTabela: 'Quantity (1 capsule)',
    GljivicnaInfekcija: 'Fungal infection',
    Koza: 'Skin',
    ProizvodJeNaStanju: 'The product is in stock',
    IsporukaDanas: 'Shipping today',
    IliNazovite: 'Or call and order at: ',
    DostavaUroku24sata: 'Delivery within 24 hours to your address'
}

export {english};