import React from 'react';
import Button from '../../components/customComponents/Button';
import "./index.scss";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import Footer from '../../components/Footer';

export default function AboutUs(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigate = (path, pathENG, pathSI) => {
        if (
            props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR'
        ) {
            animateNavigate(path);
        }
        else if(props.location === 'SI'){
            animateNavigate(pathSI);
        }
        else {
            animateNavigate(pathENG);
        }
    }

    return (
        <>
            <div className='route__aboutUs'>
                <div className='route__aboutUs__gridbox'>
                    <div>
                        <h1>{props.language.NasaPrica}</h1>
                        <p>
                            {props.language.KontentPet}
                        </p>
                        <Button className='aboutusBtn' value={props.language.IdiNaWebShop} accent='#0458AD' onClick={() => navigate('/katalog','/catalog', '/katalog')} />
                    </div>
                    <img src='images/aboutus.png' alt='' />
                </div>
            </div>
            <Footer language={props.language} />
        </>
    )
}
