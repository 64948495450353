import React from 'react';
import "./index.scss";
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import Spinner from '../../components/customComponents/Spinner';
import SingleProduct from '../../components/SingleProduct';
import Footer from '../../components/Footer';
import Button from '../../components/customComponents/Button';
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function Catalog(props) {

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigate = (path, pathENG, pathSI) => {
        if (
            props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR'
        ) {
            animateNavigate(path);
        }
        else if (props.location === 'SI') {
            animateNavigate(pathSI);
        }
        else {
            animateNavigate(pathENG);
        }
    }

    const [allProducts, setAllProducts] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    const [canPaginate, setCanPaginate] = React.useState(false);

    const getAllProducts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProductsPagination`,
            data: {
                pagination: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllProducts(res.data);
                setLoaded(true);
                if (res.data.data.length === 9) {
                    setTimeout(() => setCanPaginate(true), 1000);
                }
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllProducts();
    }, []);

    const [filters, setFilters] = React.useState([]);
    const minRef = React.useRef(null);
    const maxRef = React.useRef(null);

    const priceFilterHandler = (min, max) => {
        setCanPaginate(false);

        let countryPrice = 'CurrentPriceSI';
        switch (props.location) {
            case 'BA':
                countryPrice = 'CurrentPrice';
                break;
            case 'HR':
                countryPrice = 'CurrentPriceHR';
                break;
            case 'RS':
                countryPrice = 'CurrentPriceRS';
                break;
            case 'SI':
                countryPrice = 'CurrentPriceSI';
                break;
        }

        if (Number(min) === 0 && Number(max) >= 1000) {
            setCanPaginate(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getAllProductsPagination`,
                data: {
                    pagination: 0
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    //res.data.data = 0;
                    setAllProducts(res.data);
                    setLoaded(true);
                    setSpinner(false);
                };
            }).catch(() => {

            });
        }
        else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getAllProducts`,
                data: {
                    filters: [
                        ...filters,
                        { name: countryPrice, op: 'leq', value: Number(max) },
                        { name: countryPrice, op: 'geq', value: Number(min) }
                    ],
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    //res.data.data = 0;
                    setAllProducts(res.data);
                    setLoaded(true);
                    setSpinner(false);
                };
            }).catch(() => {

            });
        }
    }

    const searchbarRef = React.useRef(null);

    const searchThing = (value) => {
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProducts`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ProductName', op: 'like', value: value },
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                //res.data.data = 0;
                setAllProducts(res.data);
                setLoaded(true);
                setSpinner(false);
            };
        }).catch(() => {

        });
    }

    const [checked, setChecked] = React.useState(null);

    React.useEffect(() => {
        if (checked !== null) {
            setCanPaginate(false);
            setSpinner(false);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getAllProducts`,
                data: {
                    filters: [
                        ...filters,
                        { name: 'Illness', op: 'eq', value: checked },
                    ],
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    //res.data.data = 0;
                    setAllProducts(res.data);
                    setLoaded(true);
                };
            }).catch(() => {

            });
        }
        if (checked === null) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getAllProductsPagination`,
                data: {
                    pagination: 0
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    //res.data.data = 0;
                    setAllProducts(res.data);
                    setLoaded(true);
                    setCanPaginate(true);
                    setSpinner(false);
                };
            }).catch(() => {

            });
        }
    }, [checked]);

    const [loadingData, setLoadingData] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);

    const paginationData = (isInViewPort) => {
        if (isInViewPort) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getAllProductsPagination`,
                data: {
                    pagination: allProducts.data.length,

                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    if (res.data.data.length > 0) {
                        setLoadingData(true);
                        setTimeout(() => setLoadingData(false), 100);
                        res.data.data?.map((item) => {
                            allProducts.data.push(item);
                        });
                    }
                };
            }).catch(() => {
                setAllProducts({ status: 'error', data: 'Data fetch failed' });
                return setServerError(true);
            }).finally(() => {
                setSpinner(false);
            });
        }
    }

    const triggerPaginationRef = React.useRef(null);

    const useIsInViewport = () => {
        const [isIntersecting, setIsIntersecting] = React.useState(false);

        const observer = React.useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                    setSpinner(true)
                ),
            [],
        );

        React.useEffect(() => {
            observer.observe(triggerPaginationRef.current);

            return () => {
                observer.disconnect();
            };
        }, [triggerPaginationRef, observer]);
        if (isIntersecting) {
            paginationData(isIntersecting);
        }
    }

    useIsInViewport();

    const [dropdown, setDropdown] = React.useState(false);

    const dropdownHandler = () => {
        setDropdown(d => !d);
    }

    const [dropdownSelected, setDropdownSelected] = React.useState(null);
    const [orders, setOrders] = React.useState([]);

    const selectFilter = (filterFriendlyName, filterOrder, filterName) => {
        setDropdownSelected(filterFriendlyName);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProductsPagination`,
            data: {
                pagination: 0,
                orders: [
                    ...orders,
                    filterName !== '' ? { name: filterName, order: filterOrder } : ''
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllProducts(res.data);
                setLoaded(true);
                if (res.data.data.length === 9) {
                    setTimeout(() => setCanPaginate(true), 1000);
                }
            };
        }).catch(() => {

        });

        setDropdown(true);
    }

    const changeFilter = (e, filterName) => {
        let allFilters = document.querySelectorAll('#illness-filter-checkbox');
        allFilters.forEach(filter => {
            if (e.target !== filter) {
                filter.checked = false;
            }
        });
        e.target.checked ? setChecked(filterName) : setChecked(null);
    }

    const [popup, setPopup] = React.useState(false);
    const [popupSpinner, setPopupSpinner] = React.useState(false);
    const [addedProduct, setAddedProduct] = React.useState(null);
    const popupHandler = (ID) => {
        if (!popup) {
            setPopupSpinner(true);
            setPopup(p => !p);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getProductByID`,
                data: {
                    ID: ID,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setAddedProduct({
                        ProductName: res.data.data.ProductName,
                        CurrentPrice: props.location === 'BA' ? res.data.data.CurrentPrice : props.location === 'RS' ? res.data.data.CurrentPriceRS : props.location === 'HR' ? res.data.data.CurrentPriceHR : res.data.data.CurrentPriceSI,
                        OldPrice: props.location === 'BA' ? res.data.data.OldPrice : props.location === 'RS' ? res.data.data.OldPriceRS : props.location === 'HR' ? res.data.data.OldPriceHR : res.data.data.OldPriceSI,
                        ImageURL: res.data.data.ImageURL,
                    });
                };
            }).catch(() => {

            }).finally(() => {
                setPopupSpinner(false);
            });;
        }
    }

    const [mobileFilters, setMobileFIlters] = React.useState(false);
    const mobileFiltersHandler = () => {
        setMobileFIlters(m => !m);
    }

    return (
        <>
            <div className='route__catalog'>
                <div style={{ display: popup ? 'block' : 'none' }} className="route__landingPage__popup">
                    <div>
                        <div style={{ display: spinner ? 'block' : 'none' }}><Spinner width='32px' height='32px' align='center' /></div>
                        <img id="close" src="images/close.png" alt="" onClick={() => setPopup(false)} />
                        <img id="prodimg" src={addedProduct?.ImageURL} alt='' />
                        <strong>{props.language.Proizvod} {addedProduct?.ProductName} {props.language.JeDodan}</strong>
                        <span><p id='old'>{addedProduct?.OldPrice}</p><p id="new">{addedProduct?.CurrentPrice}</p></span>
                        <span id="btns">
                            <Button className='btn' accent='#405481' value={props.language.NastaviKupovinu} onClick={() => setPopup(false)} />
                            <Button className='btn' accent='#405481' value={props.language.IdiUkosaricu} onClick={() => navigate('/kosarica', '/shoppingcart', '/kosara')} />
                        </span>
                    </div>
                </div>
                <br></br>
                <p id='prodnum'>{props.language.PrikazujeSe} {allProducts.data?.length} {props.language.Proizvoda}</p>
                <div className='route__catalog__gridbox'>
                    <Button className='filtersBtn' value='Filtriraj' accent='transparent' onClick={() => mobileFiltersHandler()} />
                    <div style={{ display: mobileFilters ? 'block' : '' }} className='route__catalog__gridbox__filters'>
                        <div onClick={() => dropdownHandler()} className='route__catalog__gridbox__filters__dropdown'>
                            <p>{dropdown && dropdownSelected === null ? props.language.Izaberite : !dropdown && dropdownSelected === null ? props.language.SortirajPo : dropdownSelected} <img src={dropdown ? 'images/ddicnc.png' : 'images/ddicn.png'} alt='' /></p>
                            <div style={{ display: dropdown ? 'flex' : 'none' }}>
                                <p onClick={() => selectFilter(props.language.NazivAZ, 'asc', 'ProductName')}>{props.language.NazivAZ}</p>
                                <p onClick={() => selectFilter(props.language.NazivZA, 'desc', 'ProductName')}>{props.language.NazivZA}</p>
                                <p onClick={() => selectFilter(
                                    props.language.CijenaNajvecaNajmanja, 'desc',
                                    props.location === 'BA' ? 'CurrentPrice' :
                                        props.location === 'RS' ? 'CurrentPriceRS' :
                                            props.location === 'HR' ? 'CurrentPriceHR' :
                                                'CurrentPriceSI'
                                )}>{props.language.CijenaNajvecaNajmanja}</p>
                                <p onClick={() => selectFilter(
                                    props.language.CijenaNajmanjaNajveca, 'asc',
                                    props.location === 'BA' ? 'CurrentPrice' :
                                        props.location === 'RS' ? 'CurrentPriceRS' :
                                            props.location === 'HR' ? 'CurrentPriceHR' :
                                                'CurrentPriceSI'
                                )}>{props.language.CijenaNajmanjaNajveca}</p>
                                <p onClick={() => selectFilter(props.language.BezSortiranja, '', '')}>{props.language.BezSortiranja}</p>
                            </div>
                        </div>
                        <h4>{props.language.Cijena}</h4>
                        <div className='route__catalog__gridbox__filters__priceFilter'>
                            <span><input ref={minRef} type='number' defaultValue='0' /></span>
                            <span><input ref={maxRef} type='number' defaultValue='500' /></span>
                            <Button className='updateBtn' accent='#0458AD' onClick={() => priceFilterHandler(minRef.current.value, maxRef.current.value)} />
                        </div>
                        <h4>{props.language.Naziv}</h4>
                        <span id='search'><input onChange={() => {
                            if (!searchbarRef.current.value) {
                                getAllProducts();
                            }
                        }} ref={searchbarRef} type='text' id='searchbar' /><Button className='updateBtn' accent='#0458AD' onClick={() => searchThing(searchbarRef.current.value)} /></span>
                        <h4>{props.language.Oboljenja}</h4>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'hemoroidi') }} /><p>{props.language.Hemoroidi}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'krvni pritisak') }} /><p>{props.language.KrvniPritisak}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'dijabetes') }} /><p>{props.language.Dijabetes}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'mrsavljenje') }} /><p>{props.language.Mrsavljenje}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'prostata') }} /><p>{props.language.Prostata}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'celulit') }} /><p>{props.language.Celulit}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'potencija') }} /><p>{props.language.Potencija}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'sluh') }} /><p>{props.language.Sluh}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'vid') }} /><p>{props.language.Vid}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'gljivice') }} /><p>{props.language.GljivicnaInfekcija}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'koza') }} /><p>{props.language.Koza}</p></span>
                        <span><input type='checkbox' id='illness-filter-checkbox' onChange={(e) => { changeFilter(e, 'nedefinisano') }} /><p>{props.language.Nedefinisano}</p></span>
                    </div>
                    <div className='route__catalog__gridbox__products'>
                        <p style={{ display: allProducts.data?.length < 1 ? 'block' : 'none', textAlign: 'center', color: '#EB5757', fontWeight: '700' }}>{props.language.NemaProizvodaZaPrikaz}</p>
                        {
                            loaded ?
                                allProducts.data?.map((item, index) => {
                                    if (
                                        props.location === 'BA' && item.CurrentPrice > 0 ||
                                        props.location === 'HR' && item.CurrentPriceHR > 0 ||
                                        props.location === 'RS' && item.CurrentPriceRS > 0 ||
                                        props.location === 'SI' && item.CurrentPriceSI > 0 ||
                                        props.location !== 'BA' && props.location !== 'HR' && props.location !== 'RS' && props.location !== 'SI' && item.CurrentPriceSI > 0
                                    ) {
                                        return <SingleProduct
                                            imgURL={item.ImageURL}
                                            ProductName={item.ProductName}
                                            Description={props.location === 'SI' || props.language.ISO === 'SI' ? item.DescriptionSI : props.location === 'EN' || props.language.ISO === 'EN' ? item.DescriptionEN : props.location === 'HR' || props.language.ISO === 'HR' || props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' ? item.Description : ''}
                                            Status={item.Status === 'DOSTUPNO' && props.location === 'EN' || props.language.ISO === 'EN' ? 'AVAILABLE' : props.language.ISO === 'BA' || props.location === 'BA' || props.language.ISO === 'HR' || props.location === 'HR' || props.language.ISO === 'RS' || props.location === 'RS' || props.language.ISO === 'SI' || props.location === 'SI' ? 'DOSTUPNO' : 'AVAILABLE'}
                                            OldPrice={props.location === 'EN' || props.language.ISO === 'EN' ? item.OldPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? item.OldPrice : props.location === 'HR' || props.language.ISO === 'HR' ? item.OldPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? item.OldPriceRS : item.OldPriceSI}
                                            CurPrice={props.location === 'EN' || props.language.ISO === 'EN' ? item.CurrentPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? item.CurrentPrice : props.location === 'HR' || props.language.ISO === 'HR' ? item.CurrentPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? item.CurrentPriceRS : item.CurrentPriceSI}
                                            Currency={props.location === 'EN' || props.language.ISO === 'EN' ? 'EUR' : props.location === 'SI' || props.language.ISO === 'SI' || props.location === 'HR' || props.language.ISO === 'HR' ? 'EUR' : props.location === 'BA' || props.language.ISO === 'BA' ? 'KM' : props.location === 'RS' || props.language.ISO === 'RS' ? 'RSD' : 'EUR'}
                                            onClick={() => { props.addToCart(item.ID); popupHandler(item.ID) }}
                                            viewProduct={() => { navigate(`/proizvod/${item.Slog}`, `/product/${item.Slog}`, `/izdelek/${item.Slog}`) }}
                                            language={props.language}
                                        />
                                    }
                                })
                                : <Spinner />
                        }
                        <div id='spinnerPag'>
                            {spinner && <Spinner style={{ width: "100%", height: "64px" }} align='center' />}
                        </div>
                    </div>
                </div>
                <div style={{ display: canPaginate ? 'block' : 'none' }} ref={triggerPaginationRef}></div>
            </div>
            <Footer location={props.location} language={props.language} />
        </>
    )
}
