import React from 'react';
import { useParams } from 'react-router-dom';
import * as backendModule from "../../modules/backendModule";

export default function ViewImage() {
    const { src } = useParams();
  return (
    <div>
        <img src={`${backendModule.backendURL}/staticContent/images/${src}`} alt='' />
    </div>
  )
}
