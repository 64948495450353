import "./index.scss";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import React from "react";
import Button from "../customComponents/Button";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";

import { croatian } from "../../languages/croatian";
import { slovenian } from "../../languages/slovenian";
import { english } from "../../languages/english";
import {bosnian} from "../../languages/bosnian";
import {serbian} from "../../languages/serbian";
import Spinner from "../customComponents/Spinner";

const Header = (props) => {
    const [location, setLocation] = React.useState('BA');

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/location/getLocation`,
            ...backendModule.axiosConfig
        }).then(res => {
            setLocation(res.data.data.countryCode);
        }).catch(() => {

        });
    }, []);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [allProducts, setAllProducts] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    const [isLogged, setIsLogged] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);

    const getAllProducts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProducts`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllProducts(res.data);
                setLoaded(true);
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllProducts();
    }, []);

    const searchRef = React.useRef(null);
    const searchRefTwo = React.useRef(null);
    const [searchResult, setSearchResult] = React.useState([]);
    let curTimeout = null;

    React.useEffect(() => {
        if (!searchRef.current) return;
        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/products/getAllProducts`,
                    data: {
                        filters: [
                            ...filters,
                            { name: '--searcher', value: searchVal }
                        ]
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        setSearchResult(res.data.data);
                    };
                }).catch(() => {

                });
            }, 500);
        };
        let handlerTwo = () => {
            let searchVal = searchRefTwo?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/products/getAllProducts`,
                    data: {
                        filters: [
                            ...filters,
                            { name: '--searcher', value: searchVal }
                        ]
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        setSearchResult(res.data.data);
                    };
                }).catch(() => {

                });
            }, 500);
        };

        searchRefTwo.current.addEventListener("input", handlerTwo);
        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRefTwo.current.removeEventListener("input", handlerTwo);
                searchRef.current.removeEventListener("input", handler);
            } catch { };
        };
    }, [searchRef.current, searchRefTwo.current]);

    const [userModal, setUserModal] = React.useState(false);
    const userHandler = () => {
        if (!userModal) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/auth/checkLogin`,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setIsLogged(true);
                    setIsAdmin(res.data.data.UserInfo.Flags.isAdmin);
                    if (res.data.data.UserInfo.Flags.isAdmin || res.data.data.UserInfo.Flags.isCCagent) {
                        animateNavigate('/adminpanel');
                    }
                    else {
                        animateNavigate('/userpanel');
                    }
                }
                else {
                    setIsLogged(false);
                    setUserModal(u => !u);
                }
            }).catch(() => {

            });
        }
        else {
            setUserModal(u => !u);
            setRegistrationModal(false);
        }
    }

    const [registrationModal, setRegistrationModal] = React.useState(false);
    const registrationModalHandler = () => {
        setRegistrationModal(m => !m);
    }

    const usernameRef = React.useRef(null);
    const nameRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const emailRef = React.useRef(null);

    const register = () => {
        if (
            !nameRef.current.value ||
            !passwordRef.current.value ||
            !usernameRef.current.value ||
            !emailRef.current.value
        ) {
            msgRegRef.current.style.color = 'red';
            msgRegRef.current.style.textAlign = 'center';
            return msgRegRef.current.innerText = 'Sva polja moraju biti popunjena!';
        }

        if (passwordRef.current.value.length < 8) {
            msgRegRef.current.style.color = 'red';
            msgRegRef.current.style.textAlign = 'center';
            return msgRegRef.current.innerText = 'Šifra imati minimalno 8 karaktera';
        }

        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createNewUser`,
            data: {
                name: nameRef.current.value,
                username: usernameRef.current.value,
                password: passwordRef.current.value,
                email: emailRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                msgRegRef.current.style.color = 'green';
                msgRegRef.current.style.textAlign = 'center';
                msgRegRef.current.innerText = 'Registracija uspješna';
                setTimeout(() => {
                    userHandler();
                }, 2500);
            }
            else {
                msgRegRef.current.style.color = 'red';
                msgRegRef.current.style.textAlign = 'center';
                msgRegRef.current.innerText = res.data.data;
            }

        }).catch(() => {
            msgRegRef.current.style.color = 'red';
            msgRegRef.current.style.textAlign = 'center';
            msgRegRef.current.innerText = 'Server error';
        }).finally(() => {setSpinner(false)});
    }

    const curDispatch = useDispatch();
    const [spinner, setSpinner] = React.useState(false);
    const usernameLoginRef = React.useRef(null);
    const passwordLoginRef = React.useRef(null);
    const msgRef = React.useRef(null);
    const msgRegRef = React.useRef(null);

    const loginHandler = () => {
        if (
            !passwordLoginRef.current.value ||
            !usernameLoginRef.current.value
        ) {
            msgRef.current.style.color = 'red';
            msgRef.current.style.textAlign = 'center';
            return msgRef.current.innerText = 'Sva polja moraju biti popunjena!';
        }

        let data = {
            username: usernameLoginRef.current.value,
            password: passwordLoginRef.current.value
        };
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                userHandler();
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/auth/checkLogin`,
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        setIsAdmin(res.data.data.UserInfo.Flags.isAdmin);
                        if (res.data.data.UserInfo.Flags.isAdmin ||  res.data.data.UserInfo.Flags.isCCagent) {
                            animateNavigate('/adminpanel');
                        }
                        else {
                            animateNavigate('/userpanel');
                        }
                    }
                }).catch(() => {

                });
            }
            else {
                msgRef.current.innerText = 'Incorrect username or password';
                msgRef.current.style.color = 'red';
                setTimeout(() => {
                    msgRef.current.innerText = '';
                }, 3000);
            }
        }).catch(() => {
            msgRef.current.value = "Server is not responding";
            msgRef.current.style.color = 'red';
            setTimeout(() => {
                msgRef.current.innerText = '';
            }, 3000);
        }).finally(() => {
            setSpinner(false);
        });
    }

    const [navbar, setNavbar] = React.useState(false);
    const navbarHandler = () => {
        setNavbar(n => !n);
    }

    const [filters, setFilters] = React.useState([]);
    const getCurProduct = (value) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProducts`,
            data: {
                filters: [
                    ...filters,
                    { name: 'Illness', op: 'eq', value: value },
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                navigate(`/proizvod/${res.data.data[0].Slog}`, `/product/${res.data.data[0].Slog}`, `/izdelek/${res.data.data[0].Slog}`)
            };
        }).catch(() => {

        });
    }

    const navigate = (path, pathENG, pathSI) => {
        if (
            location === 'BA' ||
            location === 'RS' ||
            location === 'HR'
        ) {
            animateNavigate(path);
            setNavbar(false);
        }
        else if(location === 'SI'){
            animateNavigate(pathSI);
            setNavbar(false);
        }
        else {
            animateNavigate(pathENG);
            setNavbar(false);
        }
    }

    const [langDropdown, setLangDropdown] = React.useState(false);
    const changeLanguageDropdown = () => {
        setLangDropdown(m => !m);
    }

    const [curLanguage, setCurLanguage] = React.useState(props.location === 'BA' ? 'BA' : props.location === 'RS' ? 'RS' : props.location === 'HR' ? 'HR' : props.location === 'SI' ? 'SI' : 'EN');
    const changeLanguage = (language, shortName) => {
        props.setLanguage(language);
        setLangDropdown(true);
        setCurLanguage(shortName);
    }

    return <div style={{ display: window.location.href.includes('adminpanel') || window.location.href.includes('image') ? 'none' : '' }} className="component__header">
        <div style={{ display: userModal ? 'block' : 'none' }} className="component__header__userModal">
            <div style={{ display: registrationModal ? 'none' : 'flex' }} className="component__header__userModal__box">
                <img src="images/close.png" onClick={() => userHandler()} />
                <h2>{props.language.PrijaviteSe}</h2>
                <p>{props.language.KorisnickoIme}</p>
                <input type='text' ref={usernameLoginRef} />
                <p>{props.language.Lozinka}</p>
                <input type='password' ref={passwordLoginRef} />
                {!spinner && <Button className='loginBtn' value={props.language.Prijava} accent='#0458AD' onClick={() => loginHandler()} />}
                {spinner && <Spinner align='center' color='rgb(4, 88, 173)' />}
                <p>{props.language.AkoNemateRacun} <span onClick={() => registrationModalHandler()}>{props.language.RegistrirajKlikom}</span></p>
                <p ref={msgRef}></p>
            </div>
            <div style={{ display: registrationModal ? 'flex' : 'none' }} className="component__header__userModal__box">
                <img src="images/close.png" onClick={() => userHandler()} />
                <h2>{props.language.RegistrujSe}</h2>
                <p>{props.language.KorisnickoIme}</p>
                <input type='text' ref={usernameRef} />
                <p>{props.language.ImeIprezime}</p>
                <input type='text' ref={nameRef} />
                <p>{props.language.Email}</p>
                <input type='text' ref={emailRef} />
                <p>{props.language.Lozinka}</p>
                <input type='password' ref={passwordRef} />
                {!spinner && <Button className='loginBtn' value={props.language.Registracija} accent='#0458AD' onClick={() => register()} />}
                {spinner && <Spinner align='center' color='rgb(4, 88, 173)' />}
                <p ref={msgRegRef}></p>
            </div>
        </div>
        <div className="component__header__top">
            <img id="hamburger" src="images/hamburger.png" alt="" onClick={() => navbarHandler()} />
            <img id="logo" style={{ cursor: 'pointer' }} src="images/logo.svg" alt="" onClick={() => animateNavigate('/')} />
            <img id="logomob" style={{ cursor: 'pointer' }} src="images/logomob.svg" alt="" onClick={() => animateNavigate('/')} />
            <div style={{ display: navbar ? 'flex' : '' }} className="component__header__top__nav">
                <span style={{ display: navbar ? 'flex' : 'none' }}>
                    <img src="images/logo.svg" alt="" />
                    <img id="close" src="images/close2.png" alt="" onClick={() => navbarHandler()} />
                </span>
                <p onClick={() => navigate('/', '/', '/')}>{props.language.Pocetna}</p>
                <p onClick={() => navigate('/o-nama', '/aboutus', '/onas')}>{props.language.Onama}</p>
                <p onClick={() => navigate('/katalog', '/catalog', '/katalog')}>{props.language.Katalog}</p>
                <p onClick={() => navigate('/kontakt', '/contactus', '/stik')}>{props.language.KontaktirajteNas}</p>
                <p onClick={() => navigate('/zasto-prirodno', '/whynatural', '/zakaj-naravno')}>{props.language.ZastoPrirodno}</p>
            </div>
            <div id="desktopSearch" className="component__header__top__search">
                <input ref={searchRef} type='text' placeholder={props.language.Pretrazuj} />
                <img src="images/search 2.png" />
                <div style={{ display: searchResult.length > 0 && searchRef.current.value ? 'block' : 'none' }} className='component__header__top__search__results'>
                    {
                        searchResult.map((item) => {
                            return <div onClick={() => navigate(`/proizvod/${item.Slog}`, `/product/${item.SLog}`, `/izdelek/${item.Slog}`)} id="result-item">
                                <img src={item.ImageURL} alt='' />
                                <p>{item.ProductName}</p>
                            </div>
                        })
                    }
                </div>
            </div>
            <img style={{marginLeft: 'auto'}} id="user" src="images/usericon.svg" onClick={() => userHandler()} />
            <div className='component__header__top__languageIcon' onClick={() => changeLanguageDropdown()}>
                <img src="images/languageIcon.png" alt="" />
                <span>
                    <p>{curLanguage}</p>
                    <img src="images/languageDown.png" alt="" />
                </span>
                <div style={{display: langDropdown ? 'block' : 'none'}}>
                    <p onClick={() => changeLanguage(bosnian, 'BA')}>BA</p>
                    <p onClick={() => changeLanguage(serbian, 'RS')}>RS</p>
                    <p onClick={() => changeLanguage(croatian, 'HR')}>HR</p>
                    <p onClick={() => changeLanguage(slovenian, 'SI')}>SI</p>
                    <p onClick={() => changeLanguage(english, 'EN')}>EN</p>
                </div>
            </div>
        </div>
        <div className="component__header__bottom">
            <div className="component__header__bottom__nav">
                <p onClick={() => getCurProduct('potencija')}>{props.language.Potencija}</p>
                <p onClick={() => getCurProduct('hemoroidi')}>{props.language.Hemoroidi}</p>
                <p onClick={() => getCurProduct('dijabetes')}>{props.language.Dijabetes}</p>
                <p onClick={() => getCurProduct('krvni pritisak')}>{props.language.KrvniPritisak}</p>
                <p onClick={() => getCurProduct('celulit')}>{props.language.Celulit}</p>
                <p onClick={() => getCurProduct('prostata')}>{props.language.Prostata}</p>
                <p onClick={() => getCurProduct('mrsavljenje')}>{props.language.Mrsavljenje}</p>
                <p onClick={() => getCurProduct('vid')}>{props.language.Vid}</p>
                <p onClick={() => getCurProduct('sluh')}>{props.language.Sluh}</p>
                <p onClick={() => getCurProduct('gljivice')}>{props.language.GljivicnaInfekcija}</p>
                <p onClick={() => getCurProduct('koza')}>{props.language.Koza}</p>
            </div>
        </div>
        <div id="mobileSearch" className="component__header__top__search">
            <input ref={searchRefTwo} type='text' placeholder={props.language.Pretrazuj} />
            <img src="images/search 2.png" />
            <div style={{ display: searchResult.length > 0 && searchRefTwo.current.value ? 'block' : 'none' }} className='component__header__top__search__results'>
                {
                    searchResult.map((item) => {
                        return <div onClick={() => navigate(`/proizvod/${item.Slog}`, `/product/${item.Slog}`, `/izdelek/${item.Slog}`)} id="result-item">
                            <img src={item.ImageURL} alt='' />
                            <p>{item.ProductName}</p>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
};

export default Header;