import "./index.scss";
import React from "react";
import Button from "../../components/customComponents/Button";
import SingleProduct from "../../components/SingleProduct";
import Footer from "../../components/Footer";

import Spinner from "../../components/customComponents/Spinner";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/sea-green';

const LandingPage = (props) => {

    const [allProducts, setAllProducts] = React.useState([]);

    const [loaded, setLoaded] = React.useState(false);

    const getAllProducts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProductsPagination`,
            data: {
                pagination: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllProducts(res.data);
                setLoaded(true);
            }
            else {
                setLoaded(false);
            }
        }).catch(() => {
            setAllProducts('FETCH_ERROR');
            setLoaded(false);
        });
    }

    React.useEffect(() => {
        getAllProducts();
    }, []);

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigate = (path, pathENG, pathSI) => {
        if (
            props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR'
        ) {
            animateNavigate(path);
        }
        else if (props.location === 'SI') {
            animateNavigate(pathSI);
        }
        else {
            animateNavigate(pathENG);
        }
    }

    const [popup, setPopup] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [addedProduct, setAddedProduct] = React.useState(null);
    const popupHandler = (ID) => {
        if (!popup) {
            setPopup(p => !p);
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getProductByID`,
                data: {
                    ID: ID,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setAddedProduct({
                        ProductName: res.data.data.ProductName,
                        CurrentPrice: props.location === 'BA' ? res.data.data.CurrentPrice : props.location === 'RS' ? res.data.data.CurrentPriceRS : props.location === 'HR' ? res.data.data.CurrentPriceHR : res.data.data.CurrentPriceSI,
                        OldPrice: props.location === 'BA' ? res.data.data.OldPrice : props.location === 'RS' ? res.data.data.OldPriceRS : props.location === 'HR' ? res.data.data.OldPriceHR : res.data.data.OldPriceSI,
                        ImageURL: res.data.data.ImageURL,
                    });
                } else {
                    setSpinner(true);
                }
            }).catch(() => {
                setSpinner(true);
            }).finally(() => {
                setSpinner(false);
            });
        }
    }

    const emailRef = React.useRef(null);
    const chbxRef = React.useRef(null);
    const errMsgRef = React.useRef(null);
    const [subscribed, setSubscribed] = React.useState(false);
    const subscribe = () => {

        if (!emailRef.current.value || !chbxRef.current.checked) {
            return errMsgRef.current.innerText = 'Polje mora biti popunjeno, te morate prihvatiti politiku privatnosti!';
        }

        if (!emailRef.current.value.includes('@')) {
            return errMsgRef.current.innerText = 'Uneseni email nije važeći';
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/emails/addEmail`,
            data: {
                Email: emailRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                emailRef.current.value = '';
                setSubscribed(true);
                setTimeout(() => {
                    setSubscribed(false);
                }, 2500);
            }
            else {

            }
        }).catch(() => {

        });
    }

    return <div className="route__landingPage">
        <div style={{ display: popup ? 'block' : 'none' }} className="route__landingPage__popup">
            <div>
                <div style={{ display: spinner ? 'block' : 'none' }}><Spinner width='32px' height='32px' align='center' /></div>
                <img id="close" src="images/close.png" alt="" onClick={() => setPopup(false)} />
                <img id="prodimg" src={addedProduct?.ImageURL} alt='' />
                <strong>{props.language.Proizvod} {addedProduct?.ProductName} {props.language.JeDodan}</strong>
                <span><p id='old'>{addedProduct?.OldPrice}</p><p id="new">{addedProduct?.CurrentPrice}</p></span>
                <span id="btns">
                    <Button className='btn' accent='#405481' value={props.language.NastaviKupovinu} onClick={() => setPopup(false)} />
                    <Button className='btn' accent='#405481' value={props.language.IdiUkosaricu} onClick={() => navigate('/kosarica', '/shoppingcart', '/kosara')} />
                </span>
            </div>
        </div>
        <div className="route__landingPage__sectionTwo">
            <h1 style={{ display: allProducts.data?.length > 0 ? 'block' : 'none' }}>{props.language.PopularnoOveSedmice}</h1>
            <div className="route__landingPage__sectionTwo__slider">
                <div className="route__landingPage__sectionTwo__slider__body">
                    <Splide options={{
                        perPage: window.innerWidth > 700 ? Math.floor((window.innerWidth > 1526 ? 1526 : window.innerWidth) / 340) : Math.floor((window.innerWidth > 700 ? 700 : window.innerWidth) / 170)
                    }} aria-label="My Favorite Images">
                        {
                            loaded ?
                                allProducts.data?.map((item, index) => {
                                    return <SplideSlide>
                                        {console.log(item)}
                                        <SingleProduct
                                            imgURL={item.ImageURL}
                                            ProductName={item.ProductName}
                                            Description={props.location === 'SI' || props.language.ISO === 'SI' ? item.DescriptionSI : props.location === 'EN' || props.language.ISO === 'EN' ? item.DescriptionEN : props.location === 'HR' || props.language.ISO === 'HR' || props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' ? item.Description : ''}
                                            Status={item.Status === 'DOSTUPNO' && props.location === 'EN' || props.language.ISO === 'EN' ? 'AVAILABLE' : props.language.ISO === 'BA' || props.location === 'BA' || props.language.ISO === 'HR' || props.location === 'HR' || props.language.ISO === 'RS' || props.location === 'RS' || props.language.ISO === 'SI' || props.location === 'SI' ? 'DOSTUPNO' : 'AVAILABLE'}
                                            OldPrice={props.location === 'EN' || props.language.ISO === 'EN' ? item.OldPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? item.OldPrice : props.location === 'HR' || props.language.ISO === 'HR' ? item.OldPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? item.OldPriceRS : item.OldPriceSI}
                                            CurPrice={props.location === 'EN' || props.language.ISO === 'EN' ? item.CurrentPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? item.CurrentPrice : props.location === 'HR' || props.language.ISO === 'HR' ? item.CurrentPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? item.CurrentPriceRS : item.CurrentPriceSI}
                                            Currency={props.location === 'EN' || props.language.ISO === 'EN' ? 'EUR' : props.location === 'SI' || props.language.ISO === 'SI' || props.location === 'HR' || props.language.ISO === 'HR' ? 'EUR' : props.location === 'BA' || props.language.ISO === 'BA' ? 'KM' : props.location === 'RS' || props.language.ISO === 'RS' ? 'RSD' : 'EUR'}
                                            onClick={() => { popupHandler(item.ID) }}
                                            viewProduct={() => { navigate(`/proizvod/${item.Slog}`, `/product/${item.Slog}`, `/izdelek/${item.Slog}`) }}
                                            language={props.language}
                                        />

                                    </SplideSlide>

                                })
                                : ''
                        }
                        {
                            allProducts?.status === 'error' || allProducts === 'FETCH_ERROR' || !loaded ?
                                <>
                                    <p style={{ color: 'red', display: allProducts?.status === 'error' || allProducts === 'FETCH_ERROR' ? 'block' : 'none' }} >Something went wrong...</p>
                                    <Spinner align='center' />
                                </>
                                : ''
                        }
                    </Splide>
                </div>
            </div>
        </div>
        <div className="route__landingPage__sectionThree">
            <div className="route__landingPage__sectionThree__textbox">
                <h1>{props.language.prirodno100}</h1>
                <div style={{ borderBottom: '2px solid #0A7929', width: '90%', marginLeft: '-25px' }}></div>
                <p>
                    {props.language.KontentJedan}
                </p>
            </div>
            <img id="secthreepic" src="images/secThreeNaturaBackground.png" alt="" />
        </div>
        <div className="route__landingPage__sectionFour">
            <h1>{props.language.Naglasci}</h1>
            <div className="route__landingPage__sectionFour__gridbox">
                <div className="route__landingPage__sectionFour__gridbox__item">
                    <img src="images/slimKrug.png" alt="" />
                    <p>{props.language.BeskompromisnaKvaliteta}</p>
                </div>
                <div className="route__landingPage__sectionFour__gridbox__item">
                    <img src="images/potentProstataKrug.png" alt="" />
                    <p>{props.language.BeskompromisnaKvaliteta}</p>
                </div>
                <div className="route__landingPage__sectionFour__gridbox__item">
                    <img src="images/potentKrug.png" alt="" />
                    <p>{props.language.BeskompromisnaKvaliteta}</p>
                </div>
                <div className="route__landingPage__sectionFour__gridbox__item">
                    <img src="images/hyperDiaKrug.png" alt="" />
                    <p>{props.language.BeskompromisnaKvaliteta}</p>
                </div>
            </div>
        </div>
        <div className="route__landingPage__newsletter">
            <div style={{ display: subscribed ? 'block' : 'none' }} className="route__landingPage__newsletter__subscribed">
                <p>{props.language.UspjesnoSteSePrijavili}</p>
            </div>
            <span id="rotatedtext"><h1>-10%</h1><p>{props.language.NaKupovinu}</p></span>
            <img id="newsletter-pic" src="images/newsletter.png" alt="" />
            <p>{props.language.Ostvarite10popusta}</p>
            <div>
                <span id="inputbox">
                    <input type='text' placeholder="Unesite svoju e-mail adresu" ref={emailRef} />
                    <Button accent='#0458AD' value='Prijava' onClick={() => subscribe()} />
                </span>
                <span>
                    <input type='checkbox' ref={chbxRef} />
                    <p>{props.language.PotvrdjujemPolitikaPrivatnosti}</p>
                </span>
                <p id="errmsg" ref={errMsgRef}></p>
            </div>
        </div>
        <div className="route__landingPage__sectionFive">
            <h1>{props.language.KljucVasegZdravlja}</h1>
            <p>{props.language.ZbogTogaSviNasiProizvodi} </p>
            <div className="route__landingPage__sectionFive__gridbox">
                <div className="route__landingPage__sectionFive__gridbox__item">
                    <img src="images/100prirodno.png" alt="" />
                    <strong>{props.language.prirodno100}</strong>
                    <p>
                        {props.language.KontentDva}
                    </p>
                </div>
                <div className="route__landingPage__sectionFive__gridbox__item">
                    <img src="images/dokazana.png" alt="" />
                    <strong>{props.language.DokazanaDjelotvornost}</strong>
                    <p>
                        {props.language.KontentCetiri}
                    </p>
                </div>
                <div className="route__landingPage__sectionFive__gridbox__item">
                    <img src="images/topkvaliteta.png" alt="" />
                    <strong>{props.language.BeskompromisnaKvaliteta}</strong>
                    <p>
                        {props.language.KontentTri}
                    </p>
                </div>
            </div>
        </div>
        <Footer location={props.location} language={props.language} />
    </div>
};

export default LandingPage;