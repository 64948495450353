const slovenian = {
    ISO: 'SI',
    Distributer: 'Distributer za BIH:',
    DistributerRS: 'Distributer za SRB:',
    DistributerHR: 'Distributer za HR:',
    Pretplacen: 'Uspešno ste se naročili',
    Copyright: '©2019-2022 Bio Plar Vse pravice pridržane',
    UsloviKoristenja: 'Pogoji uporabe',
    Pocetna: 'Domača stran',
    Onama: 'O nas',
    Katalog: 'Katalog',
    KontaktirajteNas: 'Kontaktiraj nas',
    ZastoPrirodno: 'Zakaj naravno',
    Pretrazuj: 'Iskanje',
    PopularnoOveSedmice: 'PRILJUBLJENO TA TEDEN',
    DodajUkorpu: 'Dodaj v voziček',
    prirodno100: '100% Naravno',
    KontentJedan: '100% naravni pripravki potrjeni s certifikati ISO in GMP, ki zagotavljajo kakovost in enotnost izdelka \n\n Vrhunske sestavine, ki jih je težko najti posamezno',
    Naglasci: 'POUDARKI',
    BeskompromisnaKvaliteta: 'Brezkompromisna kakovost',
    KljucVasegZdravlja: 'KLJUČ DO VAŠEGA ZDRAVJA JE V NARAVI',
    ZbogTogaSviNasiProizvodi: 'Zato vsi naši izdelki izpolnjujejo 3 merila:',
    KontentDva: 'uporabljene sestavine so najvišje kakovosti in izključno naravnega izvora, kar potrjujejo mednarodni standardi ISO in GMP',
    KontentTri: 'zagotavljamo s ponudbo izdelkov, razvitih v sodelovanju z mednarodnimi podjetji s 40-letnimi izkušnjami v proizvodnji naravnih pripravkov',
    DokazanaDjelotvornost: 'Dokazana učinkovitost',
    KontentCetiri: '100% naravnega izvora, naše sestavine ob redni uporabi dokazano delujejo na lajšanje tegob',
    NakonStoPopunite: 'Po izpolnitvi obrazca vas bomo kontaktirali v delovnem času, pon-sob 9h - 21h.',
    ImeIprezime: 'Ime in priimek',
    BrojTelefona: 'Telefonska številka',
    NazoviMe: 'POKLIČI ME',
    DrustveneMreze: 'SOCIALNO OMREŽJE',
    PrimanjeNovosti: 'PRIJAVITE SE ZA PREJEMANJE NOVIC',
    Prihvatamo: 'SPREJEMAMO',
    NasaPrica: 'Naša zgodba',
    KontentPet: 'Podjetje Bio Plar smo ustanovili leta 2019 z namenom ponuditi visokokakovostne naravne pripravke, ki smo jih želeli kupiti, a nismo bili zadovoljni z ponudbo in kakovostjo izdelkov, ki so na voljo na trgu.na voljo na prostem trgu in sodelovanju s specializiranimi farmacevtskimi podjetja, ki proizvajaju naravne pripravke.\n\nNa ta način vam ponujamo vrhunske naravne pripravke, ki vam ob redni uporabi omogočajo izboljšanje kakovosti življenja.\n\nPoiščite izdelke, ki ustrezajo vašim potrebam, in se prepričajte o njihovi učinkovitosti.',
    IdiNaWebShop: 'POJDI V SPLETNO TRGOVINO',
    PrikazujeSe: 'Prikazovanje',
    Proizvoda: 'izdelkov',
    SortirajPo: 'RAZVRSTI PO',
    Izaberite: 'IZBERI',
    NazivAZ: 'Ime A-Z',
    NazivZA: 'Ime Z-A',
    Naruci: 'NAROČITE',
    CijenaNajvecaNajmanja: 'Najvišja cena - najnižja',
    CijenaNajmanjaNajveca: 'Najnižja cena - najvišja',
    BezSortiranja: 'Brez razvrščanja',
    Osvjezi: 'Osveži',
    Cijena: 'CENA',
    Naziv: 'IME',
    Oboljenja: 'Bolezni',
    Hemoroidi: 'Hemoroidi',
    KrvniPritisak: 'Krvni pritisk',
    Dijabetes: 'Sladkorna bolezen',
    Mrsavljenje: 'Hujšanje',
    Prostata: 'Prostata',
    Celulit: 'Celulit',
    Potencija: 'Potenca',
    Nedefinisano: 'Nedoločeno',
    KontaktForma: 'KONTAKTNI OBRAZEC',
    Ime: 'Ime',
    Telefon: 'Telefon',
    Poruka: 'Sporočilo',
    Adresa: 'Naslov',
    RadnoVrijeme: 'Delovni čas',
    ZastoPrirodno: 'Zakaj naravno',
    KontentSest: `Idealno bi bilo, če bi naravne pripravke izdelali sami iz kakovostnih sestavin, ki jih najdete v naravi.\n\nZaradi časa in denarja, ki ga je treba vložiti v pripravo, je za večino ljudi izdelava pripravkov nemogoča misija, ki je postala naša naloga. \n\n\n Visokokakovostni izdelki Bio Plar vam pomagajo lajšati vsakodnevne težave, kot so hemoroidi in debelost, ki se pojavljajo zaradi tempa in načina sodobnega življenja.`,
    NekeOdPrednostiNasihProizvodaSu: 'Nekatere prednosti naših izdelkov so:',
    Kolicina: 'Količina',
    BesplatnaDostava: 'Brezplačna dostava',
    Obrisi: 'Izbriši',
    SazetakNarudzbe: 'Povzetek naročila',
    KuponKod: 'Koda kupona',
    Primijeni: 'PRIJAVA',
    UkupnoBezPDV: 'Skupaj brez DDV',
    PDV: 'DDV',
    Dostava: 'Dostava',
    Besplatno: 'Zastonj',
    Ukupno: 'SKUPAJ',
    PopustNa: 'Popust na',
    UneseniKodJeNevazeci: 'Vnesena koda je neveljavna...',
    UneseniKodJeIstekao: 'Vnesena koda je potekla ...',
    KuponKod2: 'KODA KUPONA:',
    NastaviNaOdabirDostave: 'NADALJUJ NA IZBIRO POŠILJANJA',
    BrojKuce: 'Hišna številka',
    Grad: 'Mesto',
    Ulica: 'Ulica',
    PostanskiBroj: 'Poštna številka',
    PrekiniNarudzbu: 'PREKLIC NAROČILA',
    NastaviNaPlacanje: 'NADALJUJTE NA PLAČILO',
    NacinPlacanja: 'Način plačila',
    PunoIme: 'Polno ime in priimek imetnika kartice',
    DatumIsticanja: 'Rok uporabnosti',
    ZIP: 'ZIP',
    BrojKartice: 'Številka kartice',
    CVV: 'CVV',
    Email: 'Email',
    Napomena: 'Opomba',
    Plati: 'PLAČAJ',
    PlatiPostaru: 'Plačate poštarju ob prevzemu paketa',
    PreuzimanjePDF: 'Vaša ocena bo prenesena v naslednjih nekaj sekundah ...',
    UspjesnaNarudzba: 'Uspešno ste oddali naročilo',
    NastaviKupovinu: 'NADALJUJ Z NAKUPOVANJEM',
    NazivKompanije: 'Ime podjetja',
    AdresaKompanije: 'Naslov podjetja',
    PDVbroj: 'Davčna številka',
    IDbroj: 'ID številka',
    SvaPoljaMorajuBitiPopunjena: 'Vsa polja morajo biti izpolnjena!',
    PretplatiSe: 'NAROČITE SE',
    Isporuka: 'Dostava',
    Placanje: 'Plačilo',
    DetaljiIsporuke: 'Podrobnosti o dostavi',
    PrijaviteSe: 'PRIJAVITE SE',
    KorisnickoIme: 'Uporabniško ime',
    Lozinka: 'Geslo',
    Prijava: 'PRIJAVLJANJE',
    AkoNemateRacun: 'Če nimate računa',
    RegistrirajKlikom: 'registrirajte se s klikom tukaj',
    Registracija: 'REGISTRACIJA',
    RegistrujSe: 'REGISTRIRAJTE SE',
    NemaNaStanju: 'Trenutno ni na zalogi',
    DOSTUPNO: 'NA VOLJO',
    PorukaUspjesnoPoslana: 'Sporočilo uspešno poslano',
    NemaProizvodaZaPrikaz: 'Ni izdelkov za prikaz',
    NemateProizvodaUkosarici: 'V košarici nimate izdelkov',
    Naruci: 'Naročilo',
    DetaljiDostave: 'Podrobnosti o dostavi',
    NaciniPlacanja: 'Načini plačila',
    VaseNarudzbe: 'Vaša naročila',
    PostavkeProfila: 'Moj profil',
    VasiPodaciDostave: 'Vaši podatki o pošiljanju',
    Uredi: 'Uredi',
    PodaciNaplate: 'Podatki za obračun',
    VasiNaciniPlacanja: 'Vaši načini plačila',
    VasTrenutniNacinPlacanja: 'Vaše trenutno plačilno sredstvo',
    Izmijenite: 'Uredi:',
    DatumKreiranja: 'Datum nastanka',
    Popust: 'Popust',
    PromijeniLozinku: 'Zamenjajte geslo',
    SpremiLozinku: 'Shrani geslo',
    TrenutnaLozinka: 'Trenutno geslo',
    NovaLozinka: 'Novo geslo',
    OdjaviSe: 'Odjava',
    Dobrodosli: 'Dobrodošli',
    Sacuvaj: 'Shrani',
    DodajPodatkeZaDostavu: 'Dodajte informacije o pošiljanju',
    TrenutnoNematePodatkeZaDostavu: 'Trenutno nimate nastavljenih podatkov za dostavo, izpolnite zgornji obrazec',
    PostaviteZadaniNacinPlacanja: 'Nastavite privzeti način plačila',
    PodaciDostave: 'INFORMACIJE O POŠILJANJU',
    PodaciDostave2: 'Informacije o pošiljanju',
    PodaciPlacanja: 'PODATKI ZA PLAČILO',
    PodaciPlacanja2: 'Informacije o plačilu',
    PogledajArtikle: 'Ogled predmetov',
    Proizvod: 'Izdelek',
    PreporucenoZaVas: 'PRIPOROČAMO VAM',
    JeDodan: 'je bil dodan v vaš voziček',
    IdiUkosaricu: 'POJDI V VOZIČEK',
    Oproizvodu: 'O IZDELKU',
    Upotreba: 'UPORABA',
    Sastojci: 'SESTAVINE',
    Upozorenja: 'OPOZORILA',
    MisljenjaKupaca: 'MNENJA NAŠIH STRANK',
    Sastojak: 'Sestavina',
    Upozorenje: 'Opozorilo:',
    NaKupovinu: 'za nakupovanje',
    Ostvarite10popusta: 'S prijavo na e-novice pridobite 10% popust!',
    PotvrdjujemPolitikaPrivatnosti: 'Potrjujem, da sem seznanjen s politiko zasebnosti in dovoljujem uporabo svojega elektronskega naslova',
    UspjesnoSteSePrijavili: 'Uspešno ste se prijavili!',
    CCphoneNumber: '+381157150314',
    Sluh: 'Sluh',
    Vid: 'Vizija',
    PromjenaLozinke: 'Spreminjanje logike',
    ImatePitanja: 'Imate vprašanje o naročilu',
    PozoviteNas: 'Pokličite nas na zgoraj navedeno telefonsko številko in naši agenti vam bodo posredovali vse potrebne informacije.',
    sigurna100kupovina: '100% varno nakupovanje',
    Pobrinuli: 'Poskrbeli smo za varovanje vaših podatkov, tako da lahko varno nakupujete na naši spletni strani. Vaši podatki in zasebnost ne bodo razkriti.',
    PovratNovca: 'Povračilo',
    UkolikoIzBiloKojeg: 'Če iz kakršnega koli razloga z izdelkom ne boste zadovoljni, ga lahko vrnete v roku 15 dni, mi pa vam bomo vrnili denar.',
    PlacanjeNaRate: 'Obročno plačilo',
    NudimoVamPlacanjeNaRate: 'Ponujamo vam več različnih načinov plačila, vi pa izberite tistega, ki vam najbolj ustreza. Plačate lahko po povzetju, na do 24 obrokov ali preko spleta s kartico. Pri plačilu na 24 obrokov se o številu obrokov dogovorite v pogovoru z našimi prijaznimi operaterji.',
    UspjesnoSteNarucili: 'Uspešno ste oddali naročilo!',
    KolicinaTabela: 'Količina (1 kapsula)',
    GljivicnaInfekcija: 'Glivična okužba',
    Koza: 'Koža',
    ProizvodJeNaStanju: 'Izdelek je na zalogi',
    IsporukaDanas: 'Pošiljanje danes',
    IliNazovite: 'Ali pa pokličite in naročite na: ',
    DostavaUroku24sata: 'Dostava v 24 urah na vaš naslov'
}

export {slovenian};